import React from 'react';
import { useFormatter, useNow, useTranslations } from 'next-intl';

import { Tooltip, TooltipContent, TooltipTrigger } from '@zealy/design-system';
import {
  DiscordBrand,
  EmotionSadFilled,
  FireFilled,
  InfoLine,
  Loader2Line,
  TimerLine,
} from '@zealy/icons';
import { useAuthenticatedUser } from '@zealy/queries';
import { truncateAddress } from '@zealy/utils';

import { StatBadge } from '#components/RewardWinners/StatBadge';
import { REWARD_METHOD } from '#constants/RewardMethod';

import type { PartialQuest, RewardPropertyProps } from './types';
import { REWARD_STATUS } from './types';

const automaticRewards = ['xp', 'role'];

const getEndDate = (quest: PartialQuest) => {
  return quest.conditions.find(c => c.type === 'date' && c.operator === '<' && c.fulfilled)?.value;
};

const getRewardStatus = (reward: RewardPropertyProps['reward'], quest?: PartialQuest) => {
  const asyncReward = ['raffle', 'top'].includes(reward.method?.type ?? '');

  const isAutomatic = automaticRewards.includes(reward.type);

  if (asyncReward) {
    const endDate = quest ? getEndDate(quest) : undefined;

    if (endDate) {
      const hasEnded = new Date(endDate) < new Date();

      if (!hasEnded) return REWARD_STATUS.PENDING_END_DATE;
    } else if (quest && Number(quest.claimCount) >= quest.claimLimit) {
      return REWARD_STATUS.PENDING_CLAIM_LIMIT;
    }
  }

  if (reward.status === 'ended') return REWARD_STATUS.NOT_REWARDED;

  return isAutomatic ? REWARD_STATUS.REWARDED_AUTOMATIC : REWARD_STATUS.REWARDED_MANUAL;
};

const getIconAndVariant = (
  status: REWARD_STATUS,
): { icon: JSX.Element; variant?: 'positive' | 'negative' } => {
  switch (status) {
    case REWARD_STATUS.REWARDED_AUTOMATIC:
    case REWARD_STATUS.REWARDED_MANUAL:
      return { icon: <FireFilled />, variant: 'positive' };
    case REWARD_STATUS.NOT_REWARDED:
      return { icon: <EmotionSadFilled />, variant: 'negative' };
    default:
      return { icon: <TimerLine /> };
  }
};

export const RewardStatus: React.FC<RewardPropertyProps> = ({ reward, quest, ...rest }) => {
  const t = useTranslations('quest.rewards.details.status');

  if (!quest?.completed) return null;

  const status = getRewardStatus(reward, quest);
  const { icon, variant } = getIconAndVariant(status);

  return (
    <StatBadge label={t('label')} icon={icon} variant={variant} {...rest}>
      {t(status)}
    </StatBadge>
  );
};

export const ClaimCount: React.FC<RewardPropertyProps> = ({ reward, quest, ...rest }) => {
  const t = useTranslations('quest.rewards.details.claim-limit');

  const claimLimitSet = Number(quest?.claimLimit) > 0;

  if (!claimLimitSet) return null;

  return (
    <StatBadge label={t('label')} {...rest}>
      {Number(quest?.claimCount)} / {quest?.claimLimit}
    </StatBadge>
  );
};

export const RewardEndDate: React.FC<RewardPropertyProps> = ({ reward, quest, ...rest }) => {
  const t = useTranslations('quest.rewards.details.end-date');
  const format = useFormatter();
  const now = useNow();

  const endDate = quest ? getEndDate(quest) : undefined;

  if (!endDate) return null;

  return (
    <StatBadge label={t('label')} {...rest}>
      <time dateTime={String(endDate)}>{format.relativeTime(new Date(endDate), now)}</time>
    </StatBadge>
  );
};

export const DiscordHandle: React.FC<RewardPropertyProps> = ({ reward, ...rest }) => {
  const t = useTranslations('quest.rewards.details.discord');
  const user = useAuthenticatedUser();

  if (!user.data?.discordHandle) return null;

  return (
    <StatBadge label={t('label')} icon={<DiscordBrand />} {...rest}>
      {user.data?.discordHandle}
    </StatBadge>
  );
};

export const TokenInfo: React.FC<RewardPropertyProps> = ({ reward, ...rest }) => {
  const t = useTranslations('quest.rewards');
  const user = useAuthenticatedUser();

  if (reward.type !== 'token') return null;
  const { network, contract } = reward.settings;

  const walletAddress = user.data?.addresses?.[network];

  return (
    <>
      <StatBadge {...rest} label={t('options.token.fields.network.label')}>
        {t(`options.token.fields.network.options.${network}`)}
      </StatBadge>
      {contract !== '0' && (
        <StatBadge {...rest} label={t('options.token.fields.contract.label')}>
          {truncateAddress(contract)}
        </StatBadge>
      )}
      {walletAddress && (
        <StatBadge {...rest} label={t('details.wallet.label')}>
          {truncateAddress(walletAddress)}
        </StatBadge>
      )}
    </>
  );
};

export const RewardMethodInfo: React.FC<RewardPropertyProps> = ({ reward, ...rest }) => {
  const t = useTranslations('quest');

  return (
    <Tooltip>
      <TooltipTrigger>
        <StatBadge
          label={t('rewards-winners.label-reward-method')}
          icon={(reward.method?.type === 'fcfs'
            ? REWARD_METHOD[reward.method?.type].simple
            : REWARD_METHOD[reward.method?.type ?? 'all'].icon)({
            className: REWARD_METHOD[reward.method?.type ?? 'all']?.color,
          })}
          {...rest}
          rightIcon={<InfoLine />}
        >
          {t(`methods.type.${reward.method?.type ?? 'all'}.name`)}
        </StatBadge>
      </TooltipTrigger>
      <TooltipContent className="max-w-xs">
        {t(`methods.type.${reward.method?.type ?? 'all'}.description`)}
      </TooltipContent>
    </Tooltip>
  );
};

export const RewardMethodRankedBy: React.FC<RewardPropertyProps> = ({ reward, ...rest }) => {
  const t = useTranslations('quest.methods.type.top.options.ranked-by');

  if (reward.method?.type !== 'top') return null;

  return (
    <StatBadge label={t('label')} {...rest}>
      {t(`values.${reward.method.rankBy}`)}
    </StatBadge>
  );
};

export const RewardDistributionInfo: React.FC<RewardPropertyProps> = ({ reward, ...rest }) => {
  const t = useTranslations('quest.rewards-winners');

  if (!reward.method || reward.method.type === 'all') return null;

  if (reward.method.type === 'fcfs')
    return (
      <StatBadge
        label={t('label-rewards-won')}
        {...rest}
      >{`${reward.method.count} / ${reward.method.max}`}</StatBadge>
    );

  if (reward.method.type === 'raffle' || reward.method.type === 'vote') {
    return (
      <StatBadge label={t('label-total-rewards')} {...rest}>
        {reward.method.amount}
      </StatBadge>
    );
  }

  return (
    <StatBadge label={t('label-total-rewards')} {...rest}>
      {reward.method.topN}
    </StatBadge>
  );
};
