'use client';

import { useTranslations } from 'next-intl';

import { Button, TaskCard } from '@zealy/design-system';
import { FingerprintLine } from '@zealy/icons';
import { useRedirectUrlApiQuestTask } from '@zealy/queries';

const ConnectZealy = ({ taskId, questId }: { taskId: string; questId: string }) => {
  const generateRedirectLink = useRedirectUrlApiQuestTask({
    questId: questId,
    taskId: taskId,
  });

  const t = useTranslations();

  const title = `${t('settings.linked-accounts.connect')} ${t('common.brandName')}`;

  const onClick = () =>
    generateRedirectLink.mutate(undefined, {
      onSuccess: ({ redirectUrl }: { redirectUrl: string }) => {
        window.location.href = redirectUrl;
      },
    });

  return (
    <TaskCard title={title} type="api" className="px-200 py-300" icon={FingerprintLine}>
      <Button
        leftIcon={<FingerprintLine />}
        size="lg"
        className="w-full text-white bg-task-type-api hover:bg-ai-primary-hover active:bg-ai-primary-press"
        loading={generateRedirectLink.isPending}
        onClick={onClick}
      >
        {title}
      </Button>
    </TaskCard>
  );
};

export default ConnectZealy;
