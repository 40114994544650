import { cva } from 'class-variance-authority';

export const mascotStyle = cva('mascot', {
  variants: {
    expression: {
      happy: 'bg-mascot-happy',
      crying: 'bg-mascot-crying',
      'happy-jump': 'bg-mascot-happy-jump animate-jump',
    },
  },
});
