import { useTranslations } from 'next-intl';

import { Button, TaskCard } from '@zealy/design-system';
import { DiscordBrand } from '@zealy/icons';

import { TASK_CONFIG } from '#constants/quests/Task.constants';
import { useOauth2 } from '#hooks/useOAuth2';

const ConnectDiscord = () => {
  const discordAuth = useOauth2('discord');
  const t = useTranslations('settings.linked-accounts');

  const title = `${t('connect')} ${t('discord.title')}`;

  return (
    <TaskCard
      title={title}
      type="discord"
      className="px-200 py-300"
      icon={TASK_CONFIG.discord.icon}
    >
      <Button
        leftIcon={<DiscordBrand />}
        size="lg"
        className="w-full text-white bg-task-type-discord hover:bg-task-type-discord-hover active:bg-task-type-discord-press"
        onClick={() => discordAuth.open()}
      >
        {title}
      </Button>
    </TaskCard>
  );
};

export default ConnectDiscord;
