import { Base } from './StateBase';
import { Button } from '@zealy/design-system';
import Link from 'next/link';
import type { QuestRetryTimestampRange } from '@zealy/utils';
import clsx from 'clsx';
import { useCommunityURL } from '#hooks/useCommunityURL';
import { useNextQuest } from '#hooks/useNextQuest';
import { useTranslations } from 'next-intl';

type QuestFailProps = {
  cooldown?: QuestRetryTimestampRange | null;
  questId?: string;
  moduleId?: string;
  onRetry?: () => void;
  customError?: {
    label: string;
    details: string;
  };
};

export const QuestFail = ({
  cooldown,
  questId,
  moduleId,
  onRetry,
  customError,
}: QuestFailProps) => {
  const t = useTranslations();
  const isImmediate = cooldown === 0;
  const isInFuture = cooldown && cooldown > 0;
  const nextQuest = useNextQuest(moduleId, questId);
  const baseURL = useCommunityURL();
  const nextQuestURL = `${baseURL}/questboard/${nextQuest?.categoryId}/${nextQuest?.id}`;

  let label = t('communities.claim.failed.cooldown:never');
  if (isImmediate) {
    label = t('communities.claim.failed.cooldown:immediate');
  }
  if (isInFuture) {
    label = t('communities.claim.failed.cooldown:in-future', {
      cooldown: t(`quest.cooldown.options.${cooldown}`),
    });
  }

  return (
    <>
      <Base
        title={t('communities.claim.failed.title')}
        description={customError?.label ?? label}
        mascot="crying"
        details={customError?.details}
      >
        {nextQuest && (
          <Button
            size="lg"
            variant="muted"
            as={Link}
            href={nextQuestURL}
            className={clsx(['w-auto', isImmediate ? 'md:w-[172px]' : 'md:w-[326px]'])}
          >
            {t('common.next-quest')}
          </Button>
        )}

        {isImmediate && (
          <Button onClick={onRetry} size="lg" color="cta" className="w-auto md:w-[172px]">
            {t('common.retry')}
          </Button>
        )}
      </Base>
    </>
  );
};
