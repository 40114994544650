import React from 'react';

import type { BlockchainNetwork } from '@zealy/utils';
import { isBlockchainVerified } from '@zealy/utils';

import type { Identification } from '#constants/quests/Task.constants';

import ConnectDiscord from './ConnectDiscord';
import ConnectEmail from './ConnectEmail';
import ConnectTiktok from './ConnectTiktok';
import ConnectTwitter from './ConnectTwitter';
import ConnectWallet from './ConnectWallet';
import InputBlockchainAddress from './InputBlockchainAddress';

export const ConnectField = ({ type }: { type: Identification }) => {
  switch (type) {
    case 'discord':
      return <ConnectDiscord />;
    case 'twitter':
      return <ConnectTwitter />;
    case 'tiktok':
      return <ConnectTiktok />;
    case 'email':
      return <ConnectEmail />;
    default:
      return isBlockchainVerified(type) ? (
        <ConnectWallet blockchain={type as BlockchainNetwork} />
      ) : (
        <InputBlockchainAddress blockchain={type} />
      );
  }
};
