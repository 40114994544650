import type { RewardPropertyComponent, RewardType } from './types';
import {
  ClaimCount,
  DiscordHandle,
  RewardDistributionInfo,
  RewardEndDate,
  RewardMethodInfo,
  RewardMethodRankedBy,
  RewardStatus,
  TokenInfo,
} from './RewardProperties';

interface RewardPropertyDefinition {
  component: RewardPropertyComponent;
  shouldRender: (reward: RewardType) => boolean;
}

export const rewardPropertyDefinitions: RewardPropertyDefinition[] = [
  {
    component: RewardStatus,
    shouldRender: () => true,
  },
  {
    component: DiscordHandle,
    shouldRender: reward => reward.type === 'role',
  },
  {
    component: TokenInfo,
    shouldRender: reward => reward.type === 'token',
  },
  {
    component: RewardMethodInfo,
    shouldRender: () => true,
  },
  {
    component: RewardMethodRankedBy,
    shouldRender: reward => reward.method?.type === 'top',
  },
  {
    component: RewardDistributionInfo,
    shouldRender: reward =>
      !!reward.method && ['top', 'raffle', 'fcfs'].includes(reward.method.type),
  },
  {
    component: ClaimCount,
    shouldRender: reward => !!reward.method && ['raffle', 'fcfs'].includes(reward.method.type),
  },
  {
    component: RewardEndDate,
    shouldRender: reward =>
      !!reward.method && ['top', 'raffle', 'fcfs'].includes(reward.method.type),
  },
  // TODO: end date
  // TODO: claim limit
];
