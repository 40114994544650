import { useTranslations } from 'next-intl';
import Link from 'next/link';

import { Button } from '@zealy/design-system';

import { useCommunityURL } from '#hooks/useCommunityURL';
import { useNextQuest } from '#hooks/useNextQuest';

import { Base } from './StateBase';

type InReviewProps = {
  questId: string;
  moduleId: string;
};

export const InReview = ({ questId, moduleId }: InReviewProps) => {
  const t = useTranslations();
  const nextQuest = useNextQuest(moduleId, questId);
  const baseURL = useCommunityURL();
  const nextQuestURL = `${baseURL}/questboard/${nextQuest?.categoryId}/${nextQuest?.id}`;

  return (
    <Base
      title={t('communities.claim.in-review.title')}
      description={t('communities.claim.in-review.description')}
      mascot="happy"
    >
      {nextQuest && (
        <Button
          as={Link}
          href={nextQuestURL}
          size="lg"
          variant="muted"
          className="w-auto md:w-[326px]"
        >
          {t('common.next-quest')}
        </Button>
      )}
    </Base>
  );
};
