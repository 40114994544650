import { useCookie, useLocation } from 'react-use';

import { envConfig } from '#app/config';

export type UseOauth2Options = {
  sameWindow?: boolean;
};

const defaultOptions = { sameWindow: false };

export const openOauth = (
  provider: 'discord' | 'twitter' | 'tiktok',
  redirectTo?: string,
  invitationId?: string,
  sameWindow?: boolean,
) => {
  const params = new URLSearchParams({
    type: provider,
    subdomain: 'root',
    location: redirectTo || window.location.pathname,
    ...(invitationId ? { invitationId } : {}),
  });

  const target = sameWindow ? '_blank' : '_self';

  window.open(`${envConfig.apiV2Url}/api/authentication/oauth2/redirect?${params.toString()}`, target);
};

const getRedirectTo = ({
  currentPath,
  subdomain,
}: {
  currentPath: string;
  subdomain: string | null;
}): string => {
  if (!currentPath.includes('/signup') && !currentPath.includes('/login')) {
    return currentPath;
  }

  if (subdomain) {
    return `/cw/${subdomain}/questboard`;
  }

  return '/';
};

export const useOauth2 = (provider: 'discord' | 'twitter' | 'tiktok', invitationId?: string) => {
  const { pathname } = useLocation();
  const [_subdomain] = useCookie('subdomain');
  const redirectTo = getRedirectTo({ currentPath: pathname ?? '', subdomain: _subdomain });

  const open = (options: UseOauth2Options = defaultOptions) => {
    openOauth(provider, redirectTo, invitationId, options.sameWindow);
  };

  const getAuthCallbackUri = (code: string, state: string, subdomain: string) => {
    return `${envConfig.apiV2Url}/api/authentication/oauth2/${provider}/callback?code=${code}&subdomain=${subdomain}&state=${state}`;
  };

  const getDiscordBotCallbackUrl = (
    code: string,
    state: string,
    subdomain: string,
    permissions: string,
    guildId: string,
  ) =>
    `${envConfig.apiUrl}/communities/${subdomain}/plugins/discord/callback?code=${code}&state=${state}&permissions=${permissions}&guildId=${guildId}`;

  const getCallbackUri = (
    code: string,
    state: string,
    subdomain: string,
    permissions?: string,
    guildId?: string,
  ) => {
    const isDiscordConnectRedirection = !!permissions && !!guildId;
    if (!isDiscordConnectRedirection) {
      return getAuthCallbackUri(code, state, subdomain);
    }
    return getDiscordBotCallbackUrl(code, state, subdomain, permissions, guildId);
  };

  return { open, getCallbackUri };
};
