'use client';

import { useTranslations } from 'next-intl';

import { Button, TaskCard } from '@zealy/design-system';
import { EmailFilled } from '@zealy/icons';

import { LinkEmailTrigger } from '#app/cw/[subdomain]/settings/linked-accounts/_components/AccountSection/ConnectAccountButton/LinkEmailTrigger';
import { TASK_CONFIG } from '#constants/quests/Task.constants';

const ConnectEmail = () => {
  const t = useTranslations('settings.linked-accounts');

  const title = `${t('connect')} ${t('email.title')}`;

  return (
    <TaskCard
      title={title}
      type="invites"
      className="px-200 py-300"
      icon={TASK_CONFIG.invites.icon}
    >
      <LinkEmailTrigger asChild>
        <Button
          leftIcon={<EmailFilled />}
          size="lg"
          className="w-full !bg-ai-secondary hover:!bg-ai-secondary-hover active:!bg-ai-secondary-press text-onai-secondary"
        >
          {title}
        </Button>
      </LinkEmailTrigger>
    </TaskCard>
  );
};

export default ConnectEmail;
