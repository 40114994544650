import React from 'react';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useParams, usePathname } from 'next/navigation';

import type { ButtonProps } from '@zealy/design-system';
import type { GetQuestOutput } from '@zealy/utils';
import { Button } from '@zealy/design-system';
import { ArrowLeftLine, ArrowRightLine } from '@zealy/icons';
import { useAuthenticatedUser } from '@zealy/queries';

import { ConnectButton } from '#components/JoinCommunityBar/ConnectButton';
import { QuestStatus } from '#components/QuestCard/components/QuestStatus';

import { useQuestNavigation } from '../../../../_components/Board.hooks';

const Wrapper = ({ children }: { children: React.ReactNode }) => (
  <div
    className={clsx([
      'flex flex-row items-center gap-100',
      'lg:border-t-separators lg:py-200 lg:px-400 lg:justify-end bg-secondary z-[1]',
    ])}
  >
    {children}
  </div>
);

export const ClaimActions = ({
  quest,
  canClaim,
  isPreview,
  ...props
}: { quest: GetQuestOutput; canClaim: boolean; isPreview: boolean } & ButtonProps) => {
  const t = useTranslations();
  const user = useAuthenticatedUser();
  const { subdomain } = useParams<{
    subdomain: string;
  }>();
  const { prevQuest, nextQuest } = useQuestNavigation(quest.id);
  const pathname = usePathname();
  const isEmbedded = pathname?.includes('/embed/');

  if (!user.data)
    return (
      <Wrapper>
        <ConnectButton
          size="lg"
          className="w-full border lg:w-auto rounded-b-md lg:rounded-b-button-lg"
          data-testid="claim-button"
        >
          {t('common.connect')}
        </ConnectButton>
      </Wrapper>
    );

  return (
    <Wrapper>
      {!isEmbedded && (
        <Button
          onlyIcon
          leftIcon={<ArrowLeftLine />}
          variant="muted"
          size="lg"
          className="backdrop-blur-sm lg:hidden rounded-bl-md lg:rounded-bl-button-lg"
          as={prevQuest ? Link : undefined}
          href={
            prevQuest
              ? `/cw/${subdomain}/questboard/${prevQuest?.categoryId}/${prevQuest?.id}`
              : undefined
          }
          isDisabled={!prevQuest}
        />
      )}

      {canClaim ? (
        <Button
          size="lg"
          color="cta"
          variant="filled"
          className="flex-1 lg:flex-initial border lg:w-auto"
          data-testid="claim-button"
          {...props}
        >
          {isPreview ? t('quest.test-claim.cta') : t('common.claim')}
        </Button>
      ) : (
        <QuestStatus {...quest} />
      )}

      {!isEmbedded && (
        <Button
          onlyIcon
          leftIcon={<ArrowRightLine />}
          variant="muted"
          size="lg"
          className="backdrop-blur-sm ml-auto lg:hidden rounded-br-md lg:rounded-br-button-lg"
          as={nextQuest ? Link : undefined}
          href={
            nextQuest
              ? `/cw/${subdomain}/questboard/${nextQuest?.categoryId}/${nextQuest?.id}`
              : undefined
          }
          isDisabled={!nextQuest}
        />
      )}
    </Wrapper>
  );
};
