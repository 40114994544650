import React from 'react';

import { useRedirectUrlApiQuestTask } from '@zealy/queries';

import { TASK_CONFIG } from '#constants/quests/Task.constants';
import { cn } from '#utils/utils';

import type { QuestProps } from './Tasks.types';
import { ConnectField } from './components/ConnectField';
import ConnectZealy from './components/ConnectZealy';
import { useMissingAuth, useZealyConnectStatusForQuestAndTasks } from './Tasks.hooks';

export const Tasks = (props: QuestProps) => {
  const connect = useMissingAuth(props.quest.tasks, props.quest.rewards);
  const { missing: zealyNotConnected } = useZealyConnectStatusForQuestAndTasks(
    props.quest.id,
    props.quest.tasks,
  );

  const missingAuth = connect.length !== 0 || zealyNotConnected.length !== 0;

  return (
    <>
      {connect.map(type => {
        return <ConnectField type={type} key={`connect-${type}`} />;
      })}

      {zealyNotConnected.map(c => (
        <ConnectZealy key={`connect-${c.id}`} questId={props.quest.id} taskId={c.id} />
      ))}

      <div
        className={cn(
          'flex flex-col gap-400 pb-200',
          missingAuth ? 'pointer-events-none opacity-30 [&>*]:cursor-not-allowed grayscale' : '',
        )}
      >
        {props.quest.tasks.map(task => {
          const Component = TASK_CONFIG[task.type].claim;
          if (!Component) return null;

          return <Component key={task.id} task={task} questId={props.quest.id} />;
        })}
      </div>
    </>
  );
};
