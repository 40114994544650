import type { GetQuestOutput } from '@zealy/utils';

import { VoteRewardMethodDetails } from './VoteRewardMethodDetails';

export interface RewardMethodDetailsProps {
  quest: GetQuestOutput;
}

export const RewardMethodDetails = ({ quest }: RewardMethodDetailsProps) => {
  return (
    <>
      {quest.rewards.map((reward, index) => {
        if (reward.method?.type === 'vote') {
          return (
            <VoteRewardMethodDetails
              quest={quest}
              key={`${reward.type}-${reward.method.type}-${index}`}
            />
          );
        }

        return null;
      })}
    </>
  );
};
