import React from 'react';
import Image from 'next/image';

import type { RewardType } from '#components/RewardCard';
import { REWARD_IMAGE_SOURCES } from '#components/RewardCard/Rewards.constants';
import { cn } from '#utils/utils';

export const RewardImage = ({
  src,
  type,
  className,
  ...imageProps
}: { src?: string | null; type: RewardType } & Partial<React.ComponentProps<typeof Image>>) => {
  const imageSrc = src ?? REWARD_IMAGE_SOURCES.get(type);

  if (!imageSrc) return null;

  return (
    <Image
      width={294}
      height={294}
      draggable={false}
      src={imageSrc}
      alt="Reward"
      className={cn('h-[294px] w-[294px] object-cover rounded-component-md', className)}
      {...imageProps}
    />
  );
};
