import { useTranslations } from 'next-intl';

import { Button, TaskCard } from '@zealy/design-system';
import { TiktokBrand } from '@zealy/icons';

import { TASK_CONFIG } from '#constants/quests/Task.constants';
import { useOauth2 } from '#hooks/useOAuth2';

const ConnectTiktok = () => {
  const tiktokAuth = useOauth2('tiktok');
  const t = useTranslations('settings.linked-accounts');

  const title = `${t('connect')} ${t('tiktok.title')}`;

  return (
    <TaskCard title={title} type="tiktok" className="px-200 py-300" icon={TASK_CONFIG.tiktok.icon}>
      <Button
        leftIcon={<TiktokBrand />}
        size="lg"
        className="w-full text-white bg-task-type-tiktok hover:bg-task-type-tiktok-hover active:bg-task-type-tiktok-press"
        onClick={() => tiktokAuth.open()}
      >
        {title}
      </Button>
    </TaskCard>
  );
};

export default ConnectTiktok;
