'use client';

import { useTranslations } from 'next-intl';

import type { BlockchainNetwork } from '@zealy/utils';
import { TaskCard } from '@zealy/design-system';

import { TASK_ICONS } from '#constants/quests/TaskIcons';
import { ConnectWallet as ConnectButton } from '#features/Wallets/ConnectWallet';

const ConnectWallet = ({ blockchain = 'eth-mainnet' }: { blockchain: BlockchainNetwork }) => {
  const tChain = useTranslations('quest.type.nft.fields.network.options');
  const t = useTranslations('settings.linked-accounts');
  const title = `${t('connect')} ${tChain(blockchain)} ${t('wallet.title')}`;

  return (
    <TaskCard title={title} type="wallet" className="px-200 py-300" icon={TASK_ICONS.wallet}>
      <ConnectButton
        blockchain={blockchain}
        buttonProps={{
          color: 'default',
          className: 'w-full muted button-lg',
          size: 'lg',
        }}
      />
    </TaskCard>
  );
};

export default ConnectWallet;
