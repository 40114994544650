'use client';

import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslations } from 'next-intl';

import { Button, useEventListener } from '@zealy/design-system';
import { ChevronLeftLine, ChevronRightLine, CrossLine } from '@zealy/icons';

import type { PartialQuest } from '#components/RewardDetails';
import { RewardDetails } from '#components/RewardDetails';
import { cn } from '#utils/utils';

import type { Reward, RewardCarouselProps, RewardCarouselRef } from './RewardCarousel.types';

const RewardCarouselCard = ({
  reward,
  quest,
  setVisibleOff,
  className,
}: {
  reward: Reward;
  quest: PartialQuest;
  setVisibleOff: () => void;
  className?: string;
}) => {
  const t = useTranslations('quest');
  const rewardTitle = t(`rewards.options.${reward.type}.card-title`);

  return (
    <div
      className={cn(
        'flex-col bg-popover snap-center overflow-hidden rounded-component-md border border-separators shrink-0 max-w-[330px]',
        className,
      )}
    >
      <div className="flex flex-row items-center justify-between p-100 pl-200">
        <span className="body-component-lg-bold">{rewardTitle}</span>
        <Button onClick={setVisibleOff} variant="ghost" leftIcon={<CrossLine />} onlyIcon />
      </div>
      <div className="border-b-separators" />
      <RewardDetails reward={reward} quest={quest} orientation="vertical" />
    </div>
  );
};

// eslint-disable-next-line react/display-name
export const RewardCarousel = forwardRef<RewardCarouselRef, RewardCarouselProps>(
  ({ rewards = [], quest }, ref) => {
    /**
     * Visibility state and controllers
     */
    const [visible, setIsVisible] = useState(false);
    const setVisibleOn = () => setIsVisible(true);
    const setVisibleOff = () => setIsVisible(false);

    /**
     * Reward state and controllers
     */
    const [currentReward, setCurrentReward] = useState(0);
    const resetCurrentReward = () => setCurrentReward(0);
    const setPreviousReward = () =>
      setCurrentReward((currentReward - 1 + rewards.length) % rewards.length);
    const setNextReward = () => setCurrentReward((currentReward + 1) % rewards.length);

    /**
     * Reward data & checkers
     */
    const reward = rewards[currentReward] || rewards[0];
    const isFirstReward = currentReward === 0;
    const isLastReward = currentReward < rewards.length - 1;

    /**
     * Keyboard event listeners, we move to the next/previous reward
     * when the user presses the left/right arrow keys and close the modal
     * when the user presses the escape key.
     */
    useEventListener('keydown', event => {
      event.key === 'Escape' && setVisibleOff();
      event.key === 'ArrowLeft' && setPreviousReward();
      event.key === 'ArrowRight' && setNextReward();
    });

    /**
     * Prevent scrolling when the modal is open, we remove the whole body
     * scroll and restore it when the modal is closed.
     */
    useEffect(() => {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'auto';
      };
    }, [visible]);

    /**
     * Imperative handlers, we expose the show/hide methods to the parent component
     * so it can control the modal from outside.
     */
    useImperativeHandle(ref, () => ({
      show(initialPosition = 0) {
        if (initialPosition <= rewards.length) {
          setCurrentReward(initialPosition);
        }
        setVisibleOn();
      },
      hide() {
        if (currentReward > 0) {
          resetCurrentReward();
        }
        setVisibleOff();
      },
    }));

    /**
     * Handle click outside the modal, we close the modal when the user clicks.
     */
    useEffect(() => {
      const onClickOutside = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        !target.closest('[data-modal-id="rewards"]') && setVisibleOff();
      };
      window.addEventListener('mousedown', onClickOutside);
      return () => {
        window.removeEventListener('mousedown', onClickOutside);
      };
    }, []);

    if (!visible) return null;

    return (
      <div className="absolute flex inset-0 items-center justify-center z-40">
        <div className="flex w-full h-full items-center justify-center overflow-hidden lg:overflow-visible">
          <div className="absolute inset-0 bg-secondary opacity-80 z-20" />
          <div
            data-modal-id="rewards"
            className="flex flex-row gap-200 snap-x snap-mandatory items-start lg:relative z-30 overflow-x-scroll lg:!overflow-x-visible no-scrollbar"
          >
            <RewardCarouselCard className="hidden lg:flex" {...{ reward, quest, setVisibleOff }} />
            <>
              <div className="flex lg:hidden w-[calc(100vw)] shrink-0 h-full" />
              {rewards.map((reward, index) => (
                <RewardCarouselCard
                  className="flex lg:hidden"
                  key={index.toString()}
                  {...{ reward, quest, setVisibleOff }}
                />
              ))}
              <div className="flex lg:hidden w-[calc(100vw)] shrink-0 h-full" />
            </>
            {!isFirstReward && (
              <Button
                variant="muted"
                className="hidden lg:flex absolute top-[50%] right-[calc(100%+36px)] z-[40]"
                leftIcon={<ChevronLeftLine />}
                onlyIcon
                onClick={setPreviousReward}
              />
            )}
            {isLastReward && (
              <Button
                variant="muted"
                className="hidden lg:flex absolute top-[50%] left-[calc(100%+36px)] z-[40]"
                leftIcon={<ChevronRightLine />}
                onClick={setNextReward}
                onlyIcon
              />
            )}
          </div>
        </div>
      </div>
    );
  },
);
