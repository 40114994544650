import { useFormatter, useTranslations } from 'next-intl';
import Link from 'next/link';

import { Button } from '@zealy/design-system';

import { useCommunityURL } from '#hooks/useCommunityURL';
import { useNextQuest } from '#hooks/useNextQuest';

import { Base } from './StateBase';

type RetryLockProps = {
  questId?: string;
  moduleId?: string;
  retryAt: string;
};

export const RetryLock = ({ questId, moduleId, retryAt }: RetryLockProps) => {
  const t = useTranslations();
  const formatter = useFormatter();
  const nextQuest = useNextQuest(moduleId, questId);
  const baseURL = useCommunityURL();
  const nextQuestURL = `${baseURL}/questboard/${nextQuest?.categoryId}/${nextQuest?.id}`;

  const date = formatter.relativeTime(new Date(retryAt), Date.now());
  const title = t('communities.claim.in-cooldown.title');
  const description = t('communities.claim.in-cooldown.description', { date });

  return (
    <Base title={title} description={description} mascot="crying">
      {nextQuest && (
        <Button
          size="lg"
          variant="muted"
          as={Link}
          href={nextQuestURL}
          className="w-auto md:w-[326px]"
        >
          {t('common.next-quest')}
        </Button>
      )}
    </Base>
  );
};
