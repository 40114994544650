import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useParams } from 'next/navigation';

import type { GetQuestOutput } from '@zealy/utils';
import { Button } from '@zealy/design-system';

import { Countdown } from '#components/Countdown';

export interface RewardMethodDetailsProps {
  quest: GetQuestOutput;
}

export const VoteRewardMethodDetails = ({ quest }: RewardMethodDetailsProps) => {
  const t = useTranslations('quest.methods.type.vote.details');
  const { subdomain, moduleId, questId } = useParams<{
    moduleId: string;
    subdomain: string;
    questId: string;
  }>();

  const beforeDateCondition = quest.conditions.find(
    condition => condition.type === 'date' && condition.operator === '<',
  );

  if (!beforeDateCondition || !beforeDateCondition.value) return null;

  const date = new Date(beforeDateCondition.value);

  return (
    <div className="bg-tertiary px-100 py-150 rounded-component-sm border border-component-quaternary flex flex-row gap-200">
      <div className="shrink-0 flex-1">
        <h4 className="text-primary body-component-md-bold pb-100">{t('title')}</h4>

        <time
          dateTime={date.toISOString()}
          className="text-secondary font-light body-component-sm pb-50"
        >
          {t('date-format', { date })}
        </time>

        <Countdown date={date} />

        <p className="text-secondary font-light body-component-sm pt-100">{t('footer')}</p>
      </div>

      <Button
        color="cta"
        as={Link}
        href={`/cw/${subdomain}/questboard/${moduleId}/${questId}/submissions`}
      >
        {t('cta')}
      </Button>
    </div>
  );
};
