import type { ForwardedRef, PropsWithChildren } from 'react';
import React, { forwardRef } from 'react';

import { cn } from '@zealy/design-system';
import { mascotStyle } from './States.styles';

type BaseProps = {
  title: string;
  description?: string;
  mascot: 'happy' | 'crying' | 'happy-jump';
  details?: string;
};

export const Base = forwardRef(
  (
    { children, title, mascot, description, details }: PropsWithChildren<BaseProps>,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const styles = mascotStyle({ expression: mascot });

    return (
      <div
        ref={ref}
        className="flex flex-1 flex-col p-300 items-center justify-center bg-secondary overflow-y-auto rounded-md min-h-0 h-full absolute top-0 left-0 right-0 bottom-0 z-10 w-full"
      >
        <div className="flex flex-col gap-400 max-w-[358px] items-center">
          <div className={cn(styles, 'h-[160px] w-[142px]')} />
          <div className="flex flex-col gap-100">
            <span className="headings-headline-h3 text-primary text-center">{title}</span>
            {description && (
              <span className="body-paragraph-md text-secondary text-center">{description}</span>
            )}
            {!!details && (
              <div className="flex flex-col gap-100">
                <span className="body-paragraph-sm text-secondary text-center">{details}</span>
              </div>
            )}
          </div>
          <div className="flex flex-row gap-200">{children}</div>
        </div>
      </div>
    );
  },
);

Base.displayName = 'Base';
