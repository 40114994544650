import { useTranslations } from 'next-intl';

import { Button, TaskCard } from '@zealy/design-system';
import { TwitterFilled } from '@zealy/icons';

import { TASK_CONFIG } from '#constants/quests/Task.constants';
import { useOauth2 } from '#hooks/useOAuth2';

const ConnectTwitter = () => {
  const twitterAuth = useOauth2('twitter');

  const t = useTranslations('settings.linked-accounts');

  const title = `${t('connect')} ${t('twitter.title')}`;

  return (
    <TaskCard
      title={title}
      type="twitter"
      className="px-200 py-300"
      icon={TASK_CONFIG.twitterFollow.icon}
    >
      <Button
        leftIcon={<TwitterFilled />}
        size="lg"
        className="w-full text-white bg-task-type-twitter hover:bg-task-type-twitter-hover active:bg-task-type-twitter-press"
        onClick={() => twitterAuth.open()}
      >
        {title}
      </Button>
    </TaskCard>
  );
};

export default ConnectTwitter;
