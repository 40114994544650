import { useState } from 'react';
import { useTranslations } from 'next-intl';
import Link from 'next/link';

import type { GetQuestOutput } from '@zealy/utils';
import { Button } from '@zealy/design-system';

import { RewardDetails } from '#components/RewardDetails/RewardDetails';
import { useCommunityURL } from '#hooks/useCommunityURL';
import { useNextQuest } from '#hooks/useNextQuest';

type ClaimRewardProps = {
  rewards: GetQuestOutput['rewards'];
  questId: string;
  moduleId: string;
  quest: GetQuestOutput;
};

export const ClaimReward = ({ moduleId, questId, rewards, quest }: ClaimRewardProps) => {
  const [currentRewardIndex, setCurrentRewardIndex] = useState(0);
  const t = useTranslations();
  const nextQuest = useNextQuest(moduleId, questId);
  const baseURL = useCommunityURL();

  const totalOfRewards = rewards.length;
  const canGoNextReward = currentRewardIndex < totalOfRewards - 1;
  const currentReward = rewards[currentRewardIndex];
  const nextQuestURL = `${baseURL}/questboard/${nextQuest?.categoryId}/${nextQuest?.id}`;

  const goToNextReward = () => {
    const nextIndex = currentRewardIndex + 1;
    if (nextIndex < totalOfRewards) {
      setCurrentRewardIndex(nextIndex);
    }
  };

  return (
    <div className="inset-0 z-10 flex flex-1 flex-col bg-secondary overflow-hidden rounded-md absolute top-0 bottom-0 left-0 right-0">
      <div className="flex flex-1 flex-col p-300 items-center justify-center">
        <div className="flex flex-col flex-1 gap-400 max-w-[358px] justify-center items-center">
          <RewardDetails
            reward={currentReward}
            quest={quest}
            className="min-w-[350px] max-w-full"
          />
        </div>
        <div className="flex flex-col gap-300">
          <div className="flex flex-row gap-200">
            {nextQuest ? (
              <Button
                variant={canGoNextReward ? 'muted' : 'filled'}
                size="lg"
                color={canGoNextReward ? undefined : 'cta'}
                as={Link}
                href={nextQuestURL}
              >
                {t('common.next-quest')}
              </Button>
            ) : (
              <Button variant="muted" size="lg" as={Link} href={`${baseURL}/questboard`}>
                {t('common.back')}
              </Button>
            )}
            {canGoNextReward && (
              <Button color="cta" size="lg" onClick={goToNextReward}>
                {t('common.next-reward')}
              </Button>
            )}
          </div>
          <span className="body-paragraph-sm text-center text-tertiary">
            {t('questboard.claimed.pagination', {
              total: totalOfRewards,
              current: currentRewardIndex + 1,
            })}
          </span>
        </div>
      </div>
    </div>
  );
};
