import type { BadgeProps } from '@zealy/design-system';
import { Badge, Skeleton } from '@zealy/design-system';

import { cn } from '#utils/utils';

export const StatBadge = ({
  label,
  orientation = 'vertical',
  ...badgeProps
}: {
  label: React.ReactNode;
  orientation?: 'vertical' | 'horizontal';
} & BadgeProps) => {
  return (
    <div
      className={cn('flex flex-col items-start sm:justify-end gap-component-sm-y', {
        'sm:flex-row sm:justify-between': orientation === 'horizontal',
      })}
    >
      <p className="text-secondary body-component-sm-bold">{label}</p>
      <Badge size="xs" {...badgeProps} />
    </div>
  );
};

export const StatBadgeSkeleton = () => (
  <div className="flex flex-col gap-75">
    <Skeleton className="w-[88px] h-150" />
    <Skeleton className="w-[78px] h-badge-xs" />
  </div>
);
