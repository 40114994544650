import React from 'react';
import { useTranslations } from 'next-intl';

import { TooltipProvider } from '@zealy/design-system';

import type { TypedReward } from '#components/RewardWinners';
import { cn } from '#utils/utils';

import type { RewardPropertyProps } from './types';
import { rewardPropertyDefinitions } from './rewardDefinitions';
import { RewardImage } from './RewardImage';

export const RewardDetails: React.FC<RewardPropertyProps> = ({
  reward,
  quest,
  orientation = 'horizontal',
  className,
}) => {
  const t = useTranslations('quest');
  return (
    <div className={cn('m-200 flex flex-col gap-300', className)}>
      <div
        className={cn('flex flex-col gap-200', {
          'items-center': orientation === 'horizontal',
        })}
      >
        <RewardImage
          src={(reward as { src?: string })?.src}
          type={reward.type}
          {...(orientation === 'horizontal'
            ? {
                width: 144,
                height: 144,
                className: 'h-[144px] w-[144px]',
              }
            : {})}
        />
        <span className="headings-headline-h3">
          {t(`rewards.options.${reward.type}.value`, {
            value: reward.value,
            roleName: (reward as TypedReward<'role'>).roleName,
            symbol: (reward as TypedReward<'token'>).metadata?.symbol,
          })}
        </span>
      </div>
      <TooltipProvider>
        <div
          className={cn('grid gap-200 min-w-0 w-full', {
            'grid-cols-2': orientation === 'vertical',
          })}
        >
          {rewardPropertyDefinitions.map((definition, index) => {
            if (definition.shouldRender(reward)) {
              return (
                <definition.component
                  key={index}
                  quest={quest}
                  reward={reward}
                  orientation={orientation}
                />
              );
            }
            return null;
          })}
        </div>
      </TooltipProvider>
    </div>
  );
};
