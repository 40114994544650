import type { DateTimeFormatOptions } from 'next-intl';
import React from 'react';
import { useFormatter, useNow, useTranslations } from 'next-intl';

import type { GetQuestOutput } from '@zealy/utils';
import { Badge } from '@zealy/design-system';
import { CountFilled, RefreshCwLine, Timer2Line, TimerLine } from '@zealy/icons';
import { dayjs } from '@zealy/utils';

const DATE_FORMAT_OPTIONS: Record<GetQuestOutput['recurrence'], DateTimeFormatOptions> = {
  once: {},
  daily: { hour: '2-digit', minute: '2-digit' },
  weekly: { weekday: 'long', hour: '2-digit', minute: '2-digit' },
  monthly: { month: 'long', day: 'numeric' },
};

export const StatusBadges = ({ quest }: { quest: GetQuestOutput }) => {
  const t = useTranslations();
  const { dateTime, relativeTime } = useFormatter();
  const endDate = quest.conditions?.find(
    condition => condition.type === 'date' && condition.operator === '<',
  )?.value;

  const now = useNow({
    updateInterval: endDate && dayjs(endDate).isAfter(dayjs()) ? 1000 : 0,
  });

  const endingIn = endDate ? relativeTime(new Date(endDate), now) : '';

  const getLabelRecurrenceWithDate = (
    recurrence: GetQuestOutput['recurrence'],
    date: Date | undefined,
  ) => {
    if (!date) return '';
    const label = dateTime(date, DATE_FORMAT_OPTIONS[recurrence]);
    return `(${label})`;
  };

  const periodLabel = t(`quest.cooldown.options.${quest?.retryAfter ?? '0'}`);

  const recurrenceLabel = t(`quest.recurrence.options.${quest?.recurrence || 'once'}`);
  const recurrenceDate = getLabelRecurrenceWithDate(
    quest?.recurrence || 'once',
    quest?.opened && quest?.retryAt ? new Date(quest.retryAt) : undefined,
  );
  const recurrenceLabelWithDate = `${recurrenceLabel} ${recurrenceDate}`;

  const claimLimitLabel = quest.claimLimit
    ? t('common.claim-limit', { count: quest.claimCount ?? 0, limit: quest.claimLimit })
    : undefined;

  return (
    <div className="flex flex-row flex-wrap gap-100">
      <Badge icon={<TimerLine />}>{t('common.cooldown-period', { period: periodLabel })}</Badge>

      <Badge icon={<RefreshCwLine />}>{recurrenceLabelWithDate}</Badge>
      {claimLimitLabel && <Badge icon={<CountFilled />}>{claimLimitLabel}</Badge>}
      {endingIn && (
        <Badge icon={<Timer2Line />}>
          {t('quest.conditions.options.date.ending', {
            relativeTime: endingIn,
          })}
        </Badge>
      )}
    </div>
  );
};
