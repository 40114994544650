import type { ClaimQuestOutput, GetQuestOutput } from '@zealy/utils';

import { InReview } from './InReview';
import { QuestFail } from './QuestFail';
import { RetryLock } from './RetryLock';
import { Success } from './Success';

export const States = ({
  quest,
  claimQuest,
  onRetry,
}: {
  quest: GetQuestOutput;
  onRetry: () => void;
  claimQuest: {
    isSuccess: boolean;
    isError: boolean;
    data?: ClaimQuestOutput;
    error?:
      | {
          data?: ClaimQuestOutput;
        }
      | null
      | unknown;
  };
}) => {
  if (claimQuest.isSuccess && claimQuest.data?.status === 'success') {
    const rewards = quest.rewards.filter(
      reward => !(reward.type === 'xp' && (reward.method?.type ?? 'all') === 'all'),
    );

    return (
      <Success quest={quest} questId={quest.id} moduleId={quest.categoryId} rewards={rewards} />
    );
  }

  if (quest.completed) {
    const rewardsNotClaimed = quest.rewards.filter(
      reward => reward.status === 'claim-available' && reward.type !== 'xp',
    );

    return (
      <Success
        quest={quest}
        rewards={rewardsNotClaimed}
        questId={quest.id}
        moduleId={quest.categoryId}
      />
    );
  }

  if (quest.inReview) {
    return <InReview questId={quest.id} moduleId={quest.categoryId} />;
  }

  if (!quest.canRetry && quest.retryAt) {
    return <RetryLock questId={quest.id} moduleId={quest.categoryId} retryAt={quest.retryAt} />;
  }

  if (claimQuest.isSuccess && claimQuest.data?.status === 'inReview') {
    return <InReview questId={quest.id} moduleId={quest.categoryId} />;
  }

  if (claimQuest.isError) {
    const typedError = claimQuest.error as { data?: ClaimQuestOutput };

    const apiQuestError = typedError?.data?.taskValidations?.find(
      (taskValidation: ClaimQuestOutput['taskValidations'][number]) =>
        taskValidation.status === 'error' && taskValidation.error?.code === 'API_FAILED',
    ) as
      | {
          error: {
            code: 'API_FAILED';
            context?: {
              message?: string;
              requestId?: string;
            };
          };
        }
      | undefined;

    const customError = apiQuestError?.error?.context?.message
      ? {
          label: apiQuestError.error.context.message,
          details: `Request ID: ${apiQuestError.error.context.requestId}`,
        }
      : undefined;

    return (
      <QuestFail
        questId={quest.id}
        moduleId={quest.categoryId}
        cooldown={quest.retryAfter}
        onRetry={onRetry}
        customError={customError}
      />
    );
  }

  return null;
};
